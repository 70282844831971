import { Link } from 'gatsby';
import type { FC } from 'react';
import { AiFillInstagram } from '@react-icons/all-files/ai/AiFillInstagram';
import { FaFacebook } from '@react-icons/all-files/fa/FaFacebook';
import { FaLinkedinIn } from '@react-icons/all-files/fa/FaLinkedinIn';
import { FaYoutube } from '@react-icons/all-files/fa/FaYoutube';
import { useSubCategories } from '../../graphql/hooks/use-sub-categories';
import mapimg from '../../images/map.png';
import email from '../../images/email.png';
import mobile from '../../images/mobile.png';
import makeInIndia from '../../images/Make_In_India.png';


//grid lg:grid-cols-4 lg:gap-10 gap-6 py-10

const FooterLinks: FC = () => {
  const { allStrapiSubCategory } = useSubCategories();

  return (
    <div className="grid lg:grid-cols-5 lg:gap-10 py-10">
      <div>
        <p className="text-black text-lg font-bold uppercase mb-3">
          Quick Links
        </p>
        {footercmsPages.map(({ cmsPage, url }) => {
          const slug = new URL(url).pathname;
          const base_url =
            typeof window !== 'undefined' ? window.location.origin : '';
          return (
            <Link
              className="text-neutral-800 text-sm block pb-1.5"
              key={url}
              to={`${base_url}${slug}`}
            >
              {cmsPage}
            </Link>
          );
        })}
      </div>
      <div className="lg:col-span-2">
        <p className="text-black text-lg font-bold uppercase mb-3">PRODUCTS</p>
        <div className="grid grid-cols-2 gap-2">
          {productFooter.map(({ productName, url }) => {
            const slug = new URL(url).pathname;
            const base_url =
              typeof window !== 'undefined' ? window.location.origin : '';
            return (
              <Link
                className="text-neutral-800 text-sm"
                key={url}
                to={`${base_url}${slug}`}
              >
                {productName}
              </Link>
            );
          })}

          {/* {allStrapiSubCategory.nodes
            .slice(0, 12)
            .map(({ Title, Slug, Category }) => {
             
              return (
                <Link
                  className="text-neutral-800 text-base"
                  key={Slug}
                  to={`/${Category?.Slug}/${Slug}`}
                >
                 
                </Link>
              );
            })} */}
        </div>
      </div>
      <div className="">
        <p className="text-black text-lg font-bold uppercase mb-3">
          Industries
        </p>
        {Industries.map(({ Industry, url }) => {
          const slug = new URL(url).pathname;
          const base_url =
            typeof window !== 'undefined' ? window.location.origin : '';
          return (
            <Link
              className="text-neutral-800 text-sm block pb-1.5"
              key={url}
              to={`${base_url}${slug}`}
            >
              {Industry}
            </Link>
          );
        })}
      </div>
      <div>
        <div>
          <p className="text-black text-lg font-bold uppercase mb-3">Contact</p>
          <p className="text-neutral-800 text-sm flex pb-2">
            <i className="footer-icon">
              <img alt="Address" src={mapimg} />
            </i>{' '}
            8-B, Industrial Estate, Batala 143505
          </p>
          <Link
            className="text-neutral-800 text-sm block flex pb-2"
            to="mailto:info@sahilcnc.com"
          >
            <i className="footer-icon">
              <img alt="Email" src={email} />
            </i>{' '}
            info@sahilcnc.com
          </Link>
          <Link
            className="text-neutral-800 text-sm block flex pb-2"
            to="mailto:sales@sahilcnc.com"
          >
            <i className="footer-icon">
              <img alt="Email" src={email} />
            </i>{' '}
            sales@sahilcnc.com
          </Link>
          <Link
            className="text-neutral-800 text-sm block flex pb-2"
            to="tel:+91-8427641925"
          >
            <i className="footer-icon">
              <img alt="Mobile" src={mobile} />
            </i>{' '}
            +91-8427641925
          </Link>
          {/* <Link to="/contact-us/" className='text-neutral-800 text-sm block flex pb-2'>
        <i className="footer-icon">
        <img alt="Email" src={email} />
          </i>{' '}
        Contact us
        </Link> */}
        </div>

        <div className="flex space-x-3 items-center pt-2">
          <Link
            className="text-black"
            to="https://www.linkedin.com/company/sahil-cnc-machines/"
          >
            <FaLinkedinIn className="text-2xl fill-black" />
          </Link>

          <Link
            className="text-black"
            to="https://www.instagram.com/sahilcncmachines?igsh=MXVmODBueDZkM3Z1YQ=="
          >
            <AiFillInstagram className="text-2xl fill-black" />
          </Link>
          <Link
            className="text-black"
            to="https://www.facebook.com/profile.php?id=61556416797746&mibextid=ZbWKwL"
          >
            <FaFacebook className="text-2xl fill-black" />
          </Link>
          <Link
            className="text-black"
            to="https://www.youtube.com/@sahilcncmachines"
          >
            <FaYoutube className="text-2xl fill-black" />
          </Link>
        </div>
        <div className='flex flex-col justify-center items-center pt-6'>
          <img
            alt="loading_placeholder"
            className=""
            src={makeInIndia}
            width={180}
          />
        </div>
      </div>
    </div>
  );
};

export default FooterLinks;

const productFooter = [
  {
    productName: 'Conventional Vertical Turning Lathe',
    url: 'https://www.sahilcnc.com/turning/vtl/',
  },
  {
    productName: 'CNC Vertical Turning Lathe (Turret)',
    url: 'https://www.sahilcnc.com/turning/cnc-turret-lathe/',
  },
  {
    productName: 'CNC Vertical Turning Lathe',
    url: 'https://www.sahilcnc.com/turning/cnc-vtl/',
  },
  {
    productName: 'CNC Double Column Milling Machine',
    url: 'https://www.sahilcnc.com/milling-machines/cnc-double-column/',
  },
  {
    productName: 'CNC Gantry Milling Machine',
    url: 'https://www.sahilcnc.com/milling-machines/cnc-gantry-milling-machine/',
  },
  {
    productName: 'CNC Horizontal Boring Machine',
    url: 'https://www.sahilcnc.com/boring-machines/cnc-hbm/',
  },
  {
    productName: 'CNC Floor Boring Machine',
    url: 'https://www.sahilcnc.com/boring-machines/cnc-fb/',
  },
  {
    productName: 'CNC Vertical Turn Mill (Turret)',
    url: 'https://www.sahilcnc.com/turn-mill-machines/cnc-vtm-turret/',
  },
  {
    productName: 'CNC Vertical Turn Mill Machine',
    url: 'https://www.sahilcnc.com/turn-mill-machines/cnc-vtm/',
  },
  {
    productName: 'CNC Slant Bed Turn Mill',
    url: 'https://www.sahilcnc.com/turn-mill-machines/cnc-slant-bed-turn-mill/',
  },
  {
    productName: '5-Axis Double Column Milling Machine',
    url: 'https://www.sahilcnc.com/5-axis-solutions/double-column-milling-machine/',
  },
  {
    productName: '5-Axis Gantry Milling Machine',
    url: 'https://www.sahilcnc.com/5-axis-solutions/gantry-milling-machine/',
  },
  {
    productName: 'Cast Iron Floor Plates',
    url: 'https://www.sahilcnc.com/ci-castings/ci-floor-plates/',
  },
];

const footercmsPages = [
  {
    cmsPage: 'Blog',
    url: 'https://www.sahilcnc.com/blog/',
  },
  {
    cmsPage: 'About us',
    url: 'https://www.sahilcnc.com/about/',
  },
  {
    cmsPage: 'Sales & Service Support',
    url: 'https://www.sahilcnc.com/sales-and-services/',
  },
  {
    cmsPage: 'Services',
    url: 'https://www.sahilcnc.com/industries/services/',
  },
  {
    cmsPage: 'Contact Us',
    url: 'https://www.sahilcnc.com/contact-us/',
  },
  {
    cmsPage: 'CNC Training',
    url: 'https://www.sahilcnc.com/industries/services/cnc-training/',
  },
  {
    cmsPage: 'Annual Maintenance Contract',
    url: 'https://www.sahilcnc.com/industries/services/annual-maintenance-contract/',
  },
  {
    cmsPage: 'Privacy policy',
    url: 'https://www.sahilcnc.com/privacy-policy/',
  },
  {
    cmsPage: 'Terms & Conditions',
    url: 'https://www.sahilcnc.com/terms-and-conditions/',
  },
];

const Industries = [
  {
    Industry: 'Agriculture',
    url: 'https://www.sahilcnc.com/industries/workpiece-by-industry/agriculture/',
  },
  {
    Industry: 'Aerospace',
    url: 'https://www.sahilcnc.com/industries/workpiece-by-industry/aerospace',
  },
  {
    Industry: 'Auto Mobile',
    url: 'https://www.sahilcnc.com/industries/workpiece-by-industry/auto-mobile/',
  },
  {
    Industry: 'Defence',
    url: 'https://www.sahilcnc.com/industries/workpiece-by-industry/defence/',
  },
  {
    Industry: 'Die and Mould',
    url: 'https://www.sahilcnc.com/industries/workpiece-by-industry/die-and-mould/',
  },
  {
    Industry: 'Energy',
    url: 'https://www.sahilcnc.com/industries/workpiece-by-industry/energy/',
  },
  {
    Industry: 'Forging',
    url: 'https://www.sahilcnc.com/industries/workpiece-by-industry/forging/',
  },
  {
    Industry: 'General Engineering',
    url: 'https://www.sahilcnc.com/industries/workpiece-by-industry/general-engineering/',
  },
  {
    Industry: 'Infrastructure',
    url: 'https://www.sahilcnc.com/industries/workpiece-by-industry/infrastructure/',
  },
  {
    Industry: 'Oil & Gas',
    url: 'https://www.sahilcnc.com/industries/workpiece-by-industry/oil-and-gas/',
  },
  {
    Industry: 'Pump & Valves',
    url: 'https://www.sahilcnc.com/industries/workpiece-by-industry/pump-and-valves/',
  },
  {
    Industry: 'Railway',
    url: 'https://www.sahilcnc.com/industries/workpiece-by-industry/railway/',
  },
];
